import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Box } from "~common/components/Primitives"

const Global = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        mx: [5, "16%", "25%"],
        my: 5,
        maxWidth: 640,
      }}
    >
      <Box
        sx={{
          width: ["calc(100vw - 64px)", "42vw", "25vw"],
          height: ["calc(100vw - 64px)", "42vw", "25vw"],
          zIndex: 1,
        }}
      >
        <StaticImage
          src="./almagesto-global.jpg"
          alt="cultures involved in almagest history"
        />
      </Box>
    </Box>
  )
}

export default Global
