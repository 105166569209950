import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Section from "~common/components/Section";
import Logo from "~common/components/Logo";
import Logotypes from "./components/Logotypes";
import Global from "./components/Global";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Section bg="primary" color="secondary">
    <Section.Header>Cultural Value</Section.Header>
    <Section.Body sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative"
  }}>
      {children}
    </Section.Body>
    <Section.Footer logo color="secondary" />
  </Section>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p><strong parentName="p">{`Coolness is not enough`}</strong>{`. There is more behind the design. We wanted to talk about the evolution of astronomy during history and the many cultures involved in this process.`}</p>
    <Global mdxType="Global" />
    <p>{`Born from hellenistic roots, the Almagest was brought back to life thanks to its medieval Arabic translations (from which his actual name derives) and eventually laid out the foundations for the European scientific renaissance through the Latin translations of the XII century.`}</p>
    <Logotypes mdxType="Logotypes" />
    <p>{`Inspired by such a vibrant history, we have designed graphical themes starting from the archetypal colors of the artistic traditions touched by the Almagest.`}</p>
    <p><strong parentName="p">{`Greek`}</strong>{`, `}<strong parentName="p">{`Arabic`}</strong>{`, `}<strong parentName="p">{`Latin`}</strong>{`. Three civilization, nine themes, each designed to convey a unique feeling emphasized by the translation of the logotype.`}</p>
    <Logo color="softened-accent" sx={{
      width: ["75vw", "50vw"],
      height: ["75vw", "50vw"],
      position: "absolute",
      left: 0,
      top: 0,
      transform: "translateX(-50%)",
      opacity: 0.3
    }} mdxType="Logo" />
    <Logo color="softened-secondary" sx={{
      width: ["50vw", "25vw"],
      height: ["50vw", "25vw"],
      position: "absolute",
      right: 0,
      bottom: "25%",
      transform: "translate(50%, 16%)",
      opacity: 0.3
    }} mdxType="Logo" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      