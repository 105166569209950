import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Parallax from "~common/components/Parallax"

const Prototype = () => {
  const data = useStaticQuery(graphql`
    {
      flavioAndMax: file(relativePath: { eq: "flavio-and-max-tall.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Parallax
      bgImage={data.flavioAndMax.childImageSharp.gatsbyImageData}
      bgImageAlt="almagesto poster"
      bgImageStyle={{
        width: ["100%", "100%", "100%"],
        left: ["0%", "0%", 0],
      }}
      visible={0.8}
      sx={{
        width: "100%",
        my: 5,
      }}
    />
  )
}
export default Prototype
