import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Section from "~common/components/Section";
import Icon from "~common/components/Icon";
import Logo from "~common/components/Logo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Section bg="primary" color="secondary">
    <Section.Header>Why Almagesto</Section.Header>
    <Section.Body sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative"
  }}>
      {children}
    </Section.Body>
    <Section.Footer logo color="secondary" />
  </Section>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The Almagest is a 2nd-century astronomical treatise that describes the motions of the planets, the Moon and the Sun interpreting them through the mathematical theory of epicycles.`}</p>
    <Icon name="book" color="accent" sx={{
      width: [6, 7],
      height: [6, 7],
      my: [4, 5]
    }} mdxType="Icon" />
    <p>{`Written by Ptolemy in the Hellenistic Alexandria, it summed up all the ancient astronomical knowledge to become the foundation for the scientific development of other civilizations in the Mediterranean area such as the Arabs and the Europeans.`}</p>
    <Logo color="softened-secondary" sx={{
      width: ["42vw", "25vw"],
      height: ["42vw", "25vw"],
      position: "absolute",
      left: 0,
      bottom: 0,
      transform: "translateX(-50%)",
      opacity: 0.3,
      mixBlendMode: "multiply",
      zIndex: 1000
    }} mdxType="Logo" />
    <Logo color="softened-accent" sx={{
      width: ["66vw", "40vw"],
      height: ["66vw", "40vw"],
      position: "absolute",
      right: 0,
      bottom: 64,
      transform: "translateX(50%)",
      opacity: 0.2,
      mixBlendMode: "multiply"
    }} mdxType="Logo" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      