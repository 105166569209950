import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Page from "~common/components/Page";
import HowTheIdeaCameAbout from "~sections/HowTheIdeaCameAbout";
import ScienceMeetsDesign from "~sections/ScienceMeetsDesign";
import WhyAlmagesto from "~sections/WhyAlmagesto";
import CulturalValue from "~sections/CulturalValue";
import WhatMakesItSpecial from "~sections/WhatMakesItSpecial";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Page seo={{
  title: "The Project",
  description: "Science meets Design: a new way to look at the Solar System"
}}>
    <Page.Header downArrows>
      <Page.Header.Title>The Project</Page.Header.Title>
    </Page.Header>
    {children}
  </Page>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <HowTheIdeaCameAbout mdxType="HowTheIdeaCameAbout" />
    <ScienceMeetsDesign mdxType="ScienceMeetsDesign" />
    <WhyAlmagesto mdxType="WhyAlmagesto" />
    <CulturalValue mdxType="CulturalValue" />
    <WhatMakesItSpecial mdxType="WhatMakesItSpecial" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      