import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import ls from "localstorage-ttl"
import { providePopupContext, usePopupContext } from "~common/contexts/popup"
import { Box, Input, Label } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import Popup from "~common/components/Popup"
import Button from "~common/components/Button"
import NewsletterForm from "~newsletter/components/NewsletterForm"

const StyledInput = ({ sx, ...rest }) => (
  <Input
    sx={{
      border: theme => `1px solid ${theme.colors.secondary}!important`,
      borderRadius: 0,
      "&::placeholder": {
        opacity: 0.8,
        fontSize: "0.8rem",
        color: "secondary",
      },
      ...sx,
    }}
    {...rest}
  />
)

export default providePopupContext(() => {
  const { setIsOpen } = usePopupContext()
  const handleSubmit = () => {
    ls.set("newsletter", "signed", 1000 * 60 * 60 * 24 * 365)
    setIsOpen(false)
    if (window.fbq != null) {
      window.fbq("track", "Complete Registration")
    }
  }

  const handleClose = () => {
    ls.set("newsletter", "closed", 1000 * 60 * 60 * 24)
  }

  const [ref, itShouldOpen] = useInView()

  useEffect(() => {
    if (itShouldOpen) {
      try {
        setIsOpen(itShouldOpen && !ls.get("newsletter"))
      } catch {
        setIsOpen(true)
      }
    }
  }, [itShouldOpen, setIsOpen])

  return (
    <Box ref={ref}>
      <Popup title="Join our newsletter" closeBtn onClose={handleClose}>
        <Typography
          variant="heading2"
          sx={{
            color: "accent",
            textAlign: "center",
            width: "100%",
            mb: 4,
          }}
        >
          Sign up to stay in the loop
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <NewsletterForm
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="tiny"
              as="span"
              color="error"
              sx={{ alignSelf: "flex-end" }}
            >
              * indicates required
            </Typography>
            <Box sx={{ width: "100%", mt: 3 }}>
              <Label htmlFor="mce-EMAIL">
                <Typography variant="small" sx={{ color: "secondary", mb: 2 }}>
                  Email Address <Box as="span">*</Box>
                </Typography>
              </Label>
              <StyledInput
                type="email"
                placeholder="Your email..."
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
              />
            </Box>
            <Box sx={{ width: "100%", mt: 3 }}>
              <Label htmlFor="mce-FNAME">
                <Typography variant="small" sx={{ color: "secondary", mb: 2 }}>
                  First Name <span className="asterisk">*</span>
                </Typography>
              </Label>
              <StyledInput
                type="text"
                placeholder="Your name..."
                name="FNAME"
                className="required"
                id="mce-FNAME"
              />
            </Box>
            <Box
              sx={{
                py: [4, 5],
                "@media screen and (max-height: 768px)": {
                  py: 4,
                },
              }}
            >
              <Typography
                variant="tiny"
                as="p"
                sx={{
                  color: "secondary",
                  textAlign: ["justify", "center"],
                  mb: 3,
                  lineHeight: 1,
                  "@media screen and (max-height: 800px)": {
                    display: "none",
                  },
                }}
              >
                You can unsubscribe at any time by clicking the link in the
                footer of our emails. For information about our privacy
                practices, please visit our website.
              </Typography>
              <Typography
                variant="tiny"
                as="p"
                sx={{
                  textAlign: ["justify", "center"],
                  color: "secondary",
                  lineHeight: 1,
                  a: {
                    color: "accent",
                    "&:hover": { color: "softened-secondary" },
                  },
                }}
              >
                We use Mailchimp as our marketing platform. By clicking below to
                subscribe, you acknowledge that your information will be
                transferred to Mailchimp for processing.{" "}
                <a
                  href="https://mailchimp.com/legal/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more about Mailchimp's privacy practices here.
                </a>
              </Typography>
            </Box>
            <Button
              type="submit"
              onClick={handleSubmit}
              defaultValue="Subscribe"
              name="subscribe"
              className="button"
              variant="primary"
            >
              <Typography variant="normal">Join us</Typography>
            </Button>
          </NewsletterForm>
        </Box>
      </Popup>
    </Box>
  )
})
