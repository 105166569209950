import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Section from "~common/components/Section";
import NewsletterPopupSignUp from "~newsletter/components/NewsletterPopupSignUp";
import Prototype from "./components/Prototype";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Section bg="primary" color="secondary">
    <Section.Header>How the idea came about</Section.Header>
    <Section.Body>{children}</Section.Body>
    <Section.Footer logo color="secondary" />
    <NewsletterPopupSignUp />
  </Section>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Some time ago we asked ourselves `}<strong parentName="p">{`how the Solar System looks like in a frame of reference other than the Heliocentric one`}</strong>{`.`}</p>
    <p>{`So we started developing a software to trace out the paths of the Solar System planets as they would appear from Mercury, Venus, Earth, etc..`}</p>
    <Prototype mdxType="Prototype" />
    <p>{`The results were so aesthetically beautiful that we thought it would
be great to print them on high quality posters. Furthermore, since the
software can generate a representation of the celestial movements for
any time span, we also thought that it would be great to allow anyone
to create their own unique poster by setting their own meaningful starting and ending dates.`}</p>
    <p>{`Therefore, we decided to work on an `}<strong parentName="p">{`eye-catching design item`}</strong>{` capable
of communicating something interesting and very unique about our local
universe.`}</p>
    <p><strong parentName="p">{`That's the moment Almagesto was born.`}</strong></p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      