import React from "react"
import useTheme from "~styles/hooks/useTheme"
import { Box } from "~common/components/Primitives"
import Greek from "./almagesto-greek.svg"
import Arabic from "./almagesto-arabic.svg"
import Latin from "./almagesto-latin.svg"

const Logotypes = () => {
  const { theme } = useTheme()

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: ["column", "column", "row"],
        justifyContent: "center",
        alignItems: "center",
        mx: [5, "16%", "25%"],
        my: 5,
        maxWidth: 640,
      }}
    >
      <Box as="span" sx={{ width: ["32%", "25%", "20%"], m: 4 }}>
        <Greek
          style={{
            width: "100%",
            height: "100%",
            fill: theme.colors.secondary,
          }}
        />
      </Box>
      <Box as="span" sx={{ width: ["32%", "25%", "20%"], m: 4 }}>
        <Arabic
          style={{
            width: "100%",
            height: "100%",
            fill: theme.colors.secondary,
          }}
        />
      </Box>
      <Box as="span" sx={{ width: ["32%", "25%", "20%"], m: 4 }}>
        <Latin
          style={{
            width: "100%",
            height: "100%",
            fill: theme.colors.secondary,
          }}
        />
      </Box>
    </Box>
  )
}

export default Logotypes
